<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="isPackage===true" class="text-unset mb-0" color="error" small v-on="on">
                  {{ $t('cancel-buy') }}
                </v-btn>
                <v-btn v-if="isPackage===false" class="text-unset mb-0" color="error" small v-on="on">
                  {{ $t('cancel-freedom-buy') }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                  {{ $t('cancel-buy-title') }}
                </v-card-title>

                <v-card-text v-html="$t('cancel-buy-body', [currency])">

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="dialog = false"
                        class="text-unset"
                        small
                    >
                      {{ $t('exit') }}
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="cancelUnlock"
                        class="text-unset"
                        small
                    >
                      {{ $t('cancel-buy-confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    props: [
        'currency',
        'isPackage'

    ],
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        getUpgradeList(currentType) {
            return this.packageNames.slice(currentType + 1, this.packageNames.length)
        },
        cancelUnlock() {
            this.$apollo.mutate({
                // Query
                mutation: gql`mutation($currency:String!){cancelPackageAddress(currency:$currency)}`,
                // Parameters
                variables: {
                    currency: this.currency
                },
            }).then(() => {
                this.$router.go(0);
            })
        }
    },
}
</script>

