<template>
    <div v-if="page">
        <h2>Hotelium exclusive resort access</h2>
        <div v-if="page.doc" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <div class="row justify-content-center mb-3" v-if="!!page.lockedRate && !!page.viewer.depositAddress">
            <upgrade-info v-if="!!page.lockedRate.upgrade" :locked-rate="page.lockedRate"
                :address="this.page.viewer.depositAddress" :packages="page.packages" />
            <locked-rate-info v-else :locked-rate="page.lockedRate" :address="this.page.viewer.depositAddress"
                :packages="page.packages" />

        </div>
        <div class="row justify-content-center mb-3" v-else-if="page">
            <div class="col-6 col-md-6 col-lg-3 mb-3">
                <div class="card main-card">
                    <div class="card-body">
                        <v-card class="mx-auto" max-width="400">

                            <v-img class="black--text align-end" max-height="200px"
                                src="/static/pack/exclusive-resort-access.png">
                            </v-img>
                            <v-card-text class="pb-0 card-title-htl fsize-2" :class="'price-primary'">
                                {{ (450 / rate).toFixed(2) }} HTL
                            </v-card-text>
                        </v-card>
                        <v-dialog v-model="dialog" max-width="500px">
                            <v-card elevation="20">
                                <v-card-title class="headline grey lighten-2" primary-title>Buy Hotelium exclusive resort
                                    access</v-card-title>
                                <v-card-title class="headline">
                                    Buy access for {{ (450 / rate).toFixed(2) }} of {{ (available / 1e8).toFixed(2) }}
                                    available HTL?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="text-unset" small light flat @click="dialog = false">{{
                                        $t('close') }}</v-btn>
                                    <v-btn color="primary" class="text-unset" small dark @click="buy">
                                        Buy access for {{ (450 / rate).toFixed(2) }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                    <div class="card-footer">
                        <v-btn color="primary" text elevation="24" block small @click="dialog = true"
                            v-if="available > (450 / rate) * 1e8" :class="['btn-pack-primary', 'text-unset']">
                            {{ $t('buy-package') }}
                        </v-btn>
                        <v-bottom-sheet v-else v-model="sheet">
                            <template v-slot:activator>
                                <v-btn elevation="24" dark small block :class="['btn-pack-primary', 'text-unset']">
                                    {{ $t('secure-price') }}
                                </v-btn>
                            </template>
                            <v-list>
                                <v-subheader class="subheader">
                                    <div>You are buying the HOTELIUM EXCLUSIVE RESORT ACCESS (450€). Select the currency and
                                        secure your price.
                                        <span class="text-warning"> {{ $t('fee-not-included') }}</span>
                                    </div>
                                </v-subheader>
                                <v-list-tile v-for="currency in currencies" :key="currency._id" v-on:click="buy2(currency)"
                                    :class="currency.title.toLowerCase()">
                                    <v-list-tile-avatar>
                                        <v-avatar size="32px" tile>
                                            <img :src="cryptoImg(currency.title)" :alt="currency.title" />
                                        </v-avatar>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content class="price fsize-1">
                                        <div class="w-100">
                                            <div class="w-50">
                                                <div class="right mr-1">{{ $t('package-price') }}:</div>
                                            </div><span class="w-50 ml-1">{{
                                                getPrice(currency._id).total.toFixed(currency.decimals) }}
                                                {{ currency.title }}</span>
                                        </div>
                                        <div class="w-100">
                                            <div class="w-50">
                                                <div class="right mr-1">{{ $t('deposit-need') }}:</div>
                                            </div><span class="w-50 ml-1">{{
                                                getPrice(currency._id).diff.toFixed(currency.decimals) }}
                                                {{ currency.title }}</span>
                                        </div>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-bottom-sheet>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="font-icon-wrapper float-left mr-3 mb-3">
                <div class="loader-wrapper d-flex justify-content-center align-items-center">
                    <pacman-loader color="primary"></pacman-loader>
                </div>
                <p></p>
            </div>
        </div>
    </div>
</template>

<script>
// import PackageBox from "../components/Packages/PackageBox";
import LockedRateInfo from "../components/Packages/LockedRateInfo";
import gql from "graphql-tag";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import { mapGetters } from "vuex";
//import UpgradeInfo from "@/components/Packages/UpgradeInfo";
import PageTitle from "@/components/common/PageTitle";
export default {
    name: "TravelsPage",
    components: {
        //UpgradeInfo,
        //PackageBox,
        LockedRateInfo,
        PageTitle,
        PacmanLoader
    },
    data() {
        return {
            sheet: false,
            tiles: null,
            page: {
                doc: {
                    content: null
                },
                lockedRate: null,
                viewer: null,
                packages: null,

            },
            lockedRate: null,
            viewer: null,
            dialog: false,
            price: 450 / this.rate

        }
    },
    created() {
        this.tiles = [
            {
                _id: 0,
                img: 'HTL.png',
                title: 'HTL',
                decimals: 2
            },
            {
                _id: 1,
                img: 'BTC-logo.png',
                title: 'BTC',
                decimals: 9
            },
            {
                _id: 2,
                img: 'USDT.png',
                title: 'USDT',
                decimals: 2
            },
            {
                _id: 3,
                img: 'LTC.png',
                title: 'LTC',
                decimals: 2
            }
        ]
    },
    computed: {
        currencies() {
            if (this.page && this.page.viewer && this.page.viewer.depositAddress) {
                return this.tiles.filter(p => p.title === this.page.viewer.depositAddress.currency);
            }
            return this.tiles;
        },

        ...mapGetters({
            available: 'user/available',
            token: 'prices/rate',
            rate: 'prices/htlEur',
            htlBtc: 'prices/htlBtc',
            htlUsd: 'prices/htlUsd',
            htlLtc: 'prices/htlLtc'
        })
    },
    methods: {
        buy() {
            this.$apollo.mutate({
                mutation: gql`mutation($token:String!){buyExclusiveResortAccess(token:$token)}`,
                variables: { token: this.token },
            }).then(() => { this.$router.go(); })
        },
        buy2(currency) {
            if (this.page && this.page.viewer && this.page.viewer.depositAddress) {
                this.$apollo.mutate({
                    mutation: gql`mutation($token:String!,$currency:String!,$pack:ID!,$htlRate:Float!,$parent:Float){lockExchangeRate(token:$token,currency:$currency,pack:$pack,htlRate:$htlRate,parent:$parent)}`,
                    variables: {
                        pack: 'HERA',
                        token: this.token,
                        currency: currency.title,
                        htlRate: this.getRate(currency._id),
                        programType: 'HERA',
                        programDeposit: 'HERA',
                        parent: null
                    },
                }).then((result) => {
                    if (result.data.lockExchangeRate) {
                        this.sheet = false
                        this.$router.go(0);
                    }
                })
            }
            else {
                this.$apollo.mutate({
                    mutation: gql`mutation($token:String!,$currency:String!,$pack:ID!,$htlRate:Float!,$parent:Float){lockExchangeRateAndGetAddress(token:$token,currency:$currency,pack:$pack,htlRate:$htlRate,parent:$parent)}`,
                    variables: {
                        pack: 'HERA',
                        token: this.token,
                        currency: currency.title,
                        htlRate: this.getRate(currency._id),
                        programType: 'HERA',
                        programDeposit: 'HERA',
                        parent: null
                    },
                }).then((result) => {
                    if (result.data.lockExchangeRateAndGetAddress) {
                        this.sheet = false
                        this.$router.go(0);
                    }
                })
            }
        },
        getRate: function (currency) {
            if (this.page && this.page.viewer && this.page.viewer.depositAddress) return this.page.viewer.depositAddress.lockedRate;
            switch (currency) {
                case 0:
                    return 1;
                case 1:
                    return this.htlBtc;
                case 2:
                    return this.htlUsd;
                case 3:
                    return this.htlLtc;
            }
            return 1;
        },
        getPrice: function (currency) {
            if (this.page && this.page.viewer && this.page.viewer.depositAddress) return this.getLockedPrice();
            switch (currency) {
                case 0:
                    return this.getHtlPrice();
                case 1:
                    return this.getBtcPrice();
                case 2:
                    return this.getUsdPrice();
                case 3:
                    return this.getLtcPrice();
            }
            return this.getHtlPrice();
        },
        getLockedPrice() {
            let total = 450 * this.page.viewer.depositAddress.lockedRate / this.rate;
            let available = this.available * this.page.viewer.depositAddress.lockedRate / 1e8;
            return {
                total: total,
                available: available,
                diff: total - available
            }
        },
        getHtlPrice() {
            let total = 450 / this.rate;
            return {
                total: total,
                available: this.available / 1e8,
                diff: total - this.available / 1e8
            }
        },
        getBtcPrice() {
            let total = 450 * this.htlBtc / this.rate;
            let availableBtc = this.available * this.htlBtc / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        getUsdPrice() {
            let total = 450 * this.htlUsd / this.rate;
            let availableBtc = this.available * this.htlUsd / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        getLtcPrice() {
            let total = 450 * this.htlLtc / this.rate;
            let availableBtc = this.available * this.htlLtc / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        isMobile() {
            return screen.width <= 760;
        },
        cryptoImg(currency) {
            switch (currency) {
                case "HTL":
                    return '/images/crypto/HTL.png'
                case "BTC":
                    return '/images/crypto/BTC-logo.png'
                case "USDT":
                    return '/images/crypto/USDT.png'
                case "LTC":
                    return '/images/crypto/LTC.png'
            }
        }


    },
    apollo: {
        page: {
            query: gql`query{doc(id:"exlcusive-resort-access"){content,title},
                viewer{id, totals{tokens}, depositAddress{id,address,currency,expiresAt,lockedRate}}
                ,viewerIsAdmin}`,
            variables: {
            },
            update: data => data,
            result({ data }) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }

        }
    }
}
</script>

<style scoped>
.v-dialog__container {
    width: 100%;
}

.btn-pack-primary {
    background-color: #56aaff !important;
}

.btn-pack-secondary {
    background-color: #8bca63 !important;
}

.btn-pack-dark {
    background-color: #2f485b !important;
}

.htl {
    background-color: rgba(86, 170, 255, 0.2);
}

.btc {
    background-color: rgba(255, 112, 0, 0.2);
}

.usdt {
    background-color: rgba(186, 255, 175, 0.2);
}

.price {
    justify-content: center;
    align-items: center !important;
}

.price>div>span {
    font-weight: 700;
}

.price>div {
    display: flex;
}

@media (max-width: 420px) {
    .price {
        font-size: 12px;
    }

    .price>p>span {
        display: block;
    }

    .subheader {
        height: 74px;
        padding-bottom: 8px;
    }
}
</style>