<template>
    <div class="col-12 col-sm-8 col-lg-6">
        <div class="card-shadow-primary card-border mb-3 card">
            <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-primary">
                    <div class="menu-header-content">
                        <div class="avatar-icon-wrapper avatar-icon-lg">
                            <div class="avatar-icon rounded btn-hover-shine avatar-icon-border">
                                <img :src="packageData.thumbnail" :alt="packageData.name" />
                            </div>
                        </div>
                        <div class="menu-header-btn-pane">
                            <v-btn v-if="available > priceHtl * 1e8" color="success" class="text-unset" small @click="buy">
                                {{ $t('buy-package') }}
                            </v-btn>
                            <cancel-package-modal :currency="currency.title" :isPackage="true" />
                            <b v-if="lockedRate.programType">
                                with Freedom Program
                                <template v-if="lockedRate.programDeposit">
                                    (Deposit) - XYZ EUR
                                </template>
                            </b>

                        </div>
                    </div>
                </div>
            </div>
            <div class="scroll-area">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2 red-icon">
                                    <i class="pe-7s-info text-muted fsize-2"></i>
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading text-danger">
                                        <b>{{ $t('deposit-fee-note', [currency.title]) }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2">
                                    <img :src="cryptoImg(currency.title)" :alt="currency.title" class="logo-img-icon" />
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ $t('deposit-need') }}: <b>{{
                                        deposit.toFixed(currency.decimals) }} {{ currency.title }}</b></div>
                                </div>
                                <div class="widget-content-right">
                                    <v-btn color="primary" small class="copy-button" @click="copyDeposit">
                                        <font-awesome-icon :icon="['far', 'clone']" class="fsize-1" />
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2">
                                    <img :src="cryptoImg(currency.title)" :alt="currency.title" class="logo-img-icon" />
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ $t('total-price') }}: <b>{{
                                        price.toFixed(currency.decimals) }} {{ currency.title }}</b></div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper text-break">
                                <div class="widget-content-left center-elem mr-2">
                                    <img :src="cryptoImg(currency.title)" :alt="currency.title" class="logo-img-icon" />
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading break-word">{{ $t('deposit-address') }}: <b>{{ address.address
                                    }}</b></div>
                                </div>
                                <div class="widget-content-right position-sticky right-align" v-if="!!address.address">
                                    <v-btn color="primary" text class="copy-button" small @click="copyAddress">
                                        <font-awesome-icon :icon="['far', 'clone']" class="fsize-1" />
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2">
                                    <i class="pe-7s-stopwatch text-muted fsize-2"></i>
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ $t('price-expires') }}:
                                        <b>{{ lockedRate.expiresAt | formatDate }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2">
                                    <img :src="cryptoImg('HTL')" alt="HTL" class="logo-img-icon" />
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ $t('package-price') }}: <b>{{ priceHtl.toFixed(2) }} HTL</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left center-elem mr-2">
                                    <img :src="cryptoImg('HTL')" alt="HTL" class="logo-img-icon" />
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">
                                        <div v-html="$t('available-note', [(available / 1e8).toFixed(2)])" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <v-snackbar v-model="snackbar1" :multi-line="multiline1">
            {{ $t('amount-clipboard') }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar1 = false">
                    {{ $t('close') }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="snackbar2" :multi-line="multiline2">
            {{ $t('address-clipboard') }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar2 = false">
                    {{ $('close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowRight,
    faArrowLeft,
    faAngleUp,
    faDotCircle,
    faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { faClone } from '@fortawesome/free-regular-svg-icons'
import Vue from "vue";
import { mapGetters } from "vuex";
import CancelPackageModal from "@/components/modals/CancelPackageModal";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import gql from "graphql-tag";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown, faClone);
export default {
    name: "LockedRateInfo",
    components: {
        CancelPackageModal,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: [
        "lockedRate",
        "address",
        "packages"
    ],
    data: () => ({
        fill: { gradient: ["var(--primary)"] },
        fill1: { gradient: ["#2af598", "#009efd"] },
        fill2: { gradient: ["#fccb90", "#d57eeb"] },
        f1: { gradient: ["#3f6ad8"] },
        f2: { gradient: ["#3ac47d"] },
        f3: { gradient: ["#16aaff"] },
        f4: { gradient: ["#f7b924"] },
        f5: { gradient: ["#d92550"] },
        f6: { gradient: ["#444054"] },
        packId: null,
        pack: null,
        countdown: moment.utc(),
        loading: true,
        snackbar1: false,
        multiline1: true,
        snackbar2: false,
        multiline2: true,
        text: "",
        text2: "",
        currencyList: [
            {
                _id: 0,
                img: 'htl.webp',
                title: 'HTL',
                decimals: 2
            },
            {
                _id: 1,
                img: 'btc.png',
                title: 'BTC',
                decimals: 9
            },
            {
                _id: 2,
                img: 'usdt.webp',
                title: 'USDT',
                decimals: 2
            },
            {
                _id: 3,
                title: 'LTC',
                decimals: 4
            }
        ]
    }),
    mounted() {
        console.log("this.lockedRate");
        console.log(this.lockedRate);

        this.countdown = moment(this.lockedRate.expiresAt).diff(moment());
        var app = this;
        setInterval(function () {
            Vue.set(app, 'countdown', app.countdown - 1000)
        }, 1000);
        this.loading = false
    },
    afterMount() {
        this.text = this.$i18n.t('amount-clipboard')
        this.text2 = this.$i18n.t('address-clipboard')
    },
    methods: {
        copyAddress() {
            this.$copyText(this.address.address).then(() => this.snackbar2 = true)
        },
        copyDeposit() {
            this.$copyText(this.deposit).then(() => this.snackbar1 = true)
        },
        getRankImg(rank) {
            return require('../../assets/images/ranks/' + rank + '.png')
        },
        getPackageImg(price) {
            return this.$store.state.packages.packages.find(x => x.price === price).img;
        },
        getPacket(_id) {
            return this.$store.state.packages.packages.find(x => x._id === _id)
        },
        getProgress(expiration) {
            let expirationObj = new Date(expiration).getTime();
            let start = expirationObj - 2 * 86400000;
            let now = new Date().getTime();
            return {
                start: new Date(start),
                progress: (now - start) / (expirationObj - start) * 100
            };
        },
        cryptoImg(currency) {
            switch (currency) {
                case "HTL":
                    return '/images/crypto/HTL.png'
                case "BTC":
                    return '/images/crypto/BTC-logo.png'
                case "USDT":
                    return '/images/crypto/USDT.png'
                case "LTC":
                    return '/images/crypto/LTC.png'
            }
        },
        buy() {
            this.$apollo.mutate({
                mutation: gql`mutation($id:ID!,$token:String!,$eurUsd:Float){buyPackage(type:$id,token:$token,eurUsd:$eurUsd)}`,
                variables: {
                    id: this.lockedRate.pack,
                    token: this.lockedRate.token,
                    eurUsd: this.eurUsd
                },
            }).then(() => {
                this.$router.push("/packages")
            })
        },
        addressStr() {
            return this.address.address.toString()
        },
        depositStr() {
            return this.deposit.toString()
        }
    },
    computed: {
        ...mapGetters({
            eurUsd: 'prices/eurUsd',
            available: 'user/available',
        }),
        price() {
            return this.packageData.price * this.lockedRate.htlRate / this.lockedRate.rate;
        },
        priceHtl() {
            return this.packageData.price / this.lockedRate.rate;
        },
        deposit() {
            if (!this.lockedRate.rate) return this.packageData.price - this.available / 1e8
            return this.packageData.price * this.lockedRate.htlRate / this.lockedRate.rate - this.available / 1e8 * this.lockedRate.htlRate;
        },
        packageData() {
            return this.packages.find(p => p.id === this.lockedRate.pack)
        },
        currency() {
            return this.currencyList.find(p => p.title === this.lockedRate.currency)
        },
        countDown() {
            return moment.utc(this.countdown).format('D [days] HH[h] mm[m] ss[s]')
        },
    }
};
</script>
<style>
.confirmed {
    color: #0ba360;
}

.unconfirmed {
    color: red;
}

.pending {
    color: #1e3c72;
}

.avatar-icon {
    height: 120px !important;
    width: 100% !important;
}

.logo-img-icon {
    height: 18px;
    width: 18px;
}

.copy-button {
    max-width: 32px !important;
    min-width: 30px !important;
}

.right-align {
    right: 0;
}
</style>